<template>
  <sign-page
    title-text="字段配置"
    :request="request"
    :column-list="columnList"
    :form-parms-add="formParmsAdd"
    :form-parms-update="formParmsUpdate"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  confColumnRequest as request
} from '@/api'

export default {
  computed: {
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: 'id',
          key: 'id'
        },
        {
          type: 'input',
          label: '所属表',
          key: 'confTableId'
        },
        {
          type: 'input',
          label: '字段名',
          key: 'columnName',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '名称',
          key: 'label'
        },
        {
          type: 'input',
          label: '类型',
          key: 'type'
        },
        {
          type: 'input',
          label: '默认值',
          key: 'defaultValue'
        },
        {
          type: 'input',
          label: '可选值',
          key: 'optionalValue'
        },
        {
          type: 'input',
          label: '是否可多选',
          key: 'multipleChoose'
        },
        {
          type: 'input',
          label: '是否可为空',
          key: 'canbeEmpty'
        },
        {
          type: 'input',
          label: '配置',
          key: 'conf'
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          data.push({
            field: item.key,
            title: item.label
          })
        })
        return data
      }
    }
  },
  methods: {
  },
  data () {
    return {
      request: request
    }
  }
}
</script>
